<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="仓库">
              <a-select
                show-search
                allow-clear
                v-model="queryParam.warehouse_id"
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                placeholder="请输入仓库名称"
                @search="this.warehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="盘存方式">
              <a-select
                v-model="queryParam.method"
                placeholder="请选择"
                default-value=""
                allow-clear
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.take_inventory_method">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select
                v-model="queryParam.status"
                placeholder="请选择"
                default-value=""
                allow-clear
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.take_inventory_status">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="corporationTakeInventory">公司盘存</a-button>
      <a-button type="primary" icon="check" @click="warehouseTakeInventory">仓库盘存</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)">详情</a>
          <a-divider type="vertical" v-if="record.status === 1"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            v-if="record.status === 1"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" v-if="record.status === 1"/>
          <a v-if="record.status === 1" @click="handleUpdate(record)">查看盘存差异</a>
        </template>
      </span>
    </s-table>
    <corporation-take-inventory-form
      ref="corporationTakeInventoryForm"
      v-if="corporationTakeInventoryVisible"
      :visible.sync="corporationTakeInventoryVisible"
      @cancel="handleCorporationTakeInventoryCancel"
      @ok="handleCorporationTakeInventoryOK"
    />
    <warehouse-take-inventory-form
      ref="warehouseTakeInventoryForm"
      v-if="warehouseTakeInventoryVisible"
      :visible.sync="warehouseTakeInventoryVisible"
      @cancel="handleWarehouseTakeInventoryCancel"
      @ok="handleWarehouseTakeInventoryOK"
    />
    <diff-list-form
      v-if="visible"
      ref="DiffListForm"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleDiffCancel"
      @ok="handleOk"
    />
    <check-diff-list-form
      v-if="checkVisible"
      ref="CheckDiffListForm"
      :visible="checkVisible"
      :loading="checkConfirmLoading"
      :model="check_mdl"
      @cancel="handleCheckDiffCancel"
      @ok="handleCheckOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import TakeInventoryStatusForm from '../modules/TakeInventoryStatusForm'
import CorporationTakeInventoryForm from '../modules/CorporationTakeInventoryForm'
import WarehouseTakeInventoryForm from '../modules/WarehouseTakeInventoryForm'
import DiffListForm from '../modules/DiffListForm'
import CheckDiffListForm from '../modules/CheckDiffListForm'
import debounce from 'lodash/debounce'
import {
  wms_warehouse_take_inventory_create,
   wms_warehouse_take_inventory_delete,
  wms_warehouse_take_inventory_list,
  wms_warehouse_take_inventory_confirm_diff_create,
  wms_warehouse_take_inventory_check_uninventory_diff,
  wms_warehouse_take_inventory_check_uninventory_diff_create
} from '@/api/c_wms_period_inventory'

import { warehouse_list } from '@/api/c_wms_warehouse'

export default {
  name: 'TableList',
  components: {
    STable,
    TakeInventoryStatusForm,
    CorporationTakeInventoryForm,
    WarehouseTakeInventoryForm,
    DiffListForm,
    CheckDiffListForm
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      corporationTakeInventoryVisible: false,
      warehouseTakeInventoryVisible: false,
      checkVisible: false,
      loading: false,
      confirmLoading: false,
      checkConfirmLoading: false,
      warehouse_fetching: false,
      dataSource_wms_warehouse: [],
      // 创建窗口控制
      visible: false,
      mdl: {},
      check_mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '盘存方式',
          dataIndex: 'method',
          customRender: (text) => this.$Dictionaries.take_inventory_method[text],
          ellipsis: true
        },
        {
          title: '仓库',
          dataIndex: 'warehouse',
          ellipsis: true
        },
        {
          title: '企业',
          dataIndex: 'supplier',
          ellipsis: true
        },
        {
          title: '盘余',
          dataIndex: 'diff_more',
          ellipsis: true
        },
        {
          title: '盘亏',
          dataIndex: 'diff_less',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.take_inventory_status[text],
          ellipsis: true
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '250px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return wms_warehouse_take_inventory_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    this.handleWarehouseSearch(undefined)
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // 1，公司 2.仓库 3.随机
    corporationTakeInventory () {
      this.corporationTakeInventoryVisible = true
    },
    warehouseTakeInventory () {
      this.warehouseTakeInventoryVisible = true
    },
    handleCorporationTakeInventoryCancel () {
      this.corporationTakeInventoryVisible = false
    },
    handleCorporationTakeInventoryOK () {
      const form = this.$refs.corporationTakeInventoryForm.form
      form.validateFields((errors, values) => {
        if (!errors) {
          wms_warehouse_take_inventory_create({ method: 1, ...values }).then(res => {
            console.log(res, '盘存')
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.corporationTakeInventoryVisible = false
          })
        }
      })
    },
    handleWarehouseTakeInventoryCancel () {
      this.warehouseTakeInventoryVisible = false
    },
    handleWarehouseTakeInventoryOK () {
      const form = this.$refs.warehouseTakeInventoryForm.form
      form.validateFields((errors, values) => {
        if (!errors) {
          wms_warehouse_take_inventory_create({ method: 2, ...values }).then(res => {
            console.log(res, '盘存')
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.warehouseTakeInventoryVisible = false
          })
        }
      })
    },
    handleDiffCancel () {
      this.visible = false
    },
    handleCheckDiffCancel () {
      this.checkVisible = false
    },
    handleOk (id, confirm_or_not) {
      wms_warehouse_take_inventory_check_uninventory_diff({ take_inventory_id: id }).then(res => {
        if (res.code === 1000) {
          if (res.data !== undefined) {
            if (res.data.length > 0) {
              this.confirmLoading = true
              wms_warehouse_take_inventory_confirm_diff_create({ take_inventory_id: id, confirm_or_not: confirm_or_not }).then(res => {
                console.log(res)
                if (res.code === 1000) {
                  this.$refs.table.refresh()
                  this.confirmLoading = false
                  this.visible = false
                }
              }).catch((e) => {
                console.log(e)
                this.confirmLoading = false
                this.visible = false
              }).finally(() => {
                this.confirmLoading = false
                this.visible = false
              })
            } else {
              this.check_mdl.id = id
              this.checkConfirmLoading = false
              this.checkVisible = false
            }
          }
        }
      })
    },
    handleCheckOk (id, result) {
      wms_warehouse_take_inventory_check_uninventory_diff_create({
        take_inventory_id: id,
        take_inventory_results: result
      }).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleDetail (record) {
      this.$router.push({
        path: '/zb/wms/wms_inventory_detail/' + record.id,
        query: {
          warehouse_id: record.warehouse_id,
          status: record.status,
          warehouse: record.warehouse
        }
      })
    },
    handleDelete (record) {
      wms_warehouse_take_inventory_delete(record.id).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleUpdate (record) {
      this.mdl = record
      this.visible = true
    },
    handleWarehouseSearch (value) {
      this.dataSource_wms_warehouse = []
      this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleWmsWarehouseChange (value) {
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
    }
  }
}
</script>
